<template>
  <v-container fluid>
    <v-card elevation="0">
      <v-card-title class="mb-4">{{ client.name }}</v-card-title>
      <v-card-subtitle align="left" class="pt-0 pb-0">담당자</v-card-subtitle>
      <v-card-text class="pt-0 pb-0">
        <v-row align="center">
          <v-col>
            <v-text-field
              label="이름"
              v-model="client.contact_name"
              readonly
              filled
              background-color="#EFEFF7"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="연락처"
              v-model="client.contact_phone"
              readonly
              filled
              background-color="#EFEFF7"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Email"
              v-model="client.contact_email"
              readonly
              filled
              background-color="#EFEFF7"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="홈페이지"
              v-model="client.contact_url"
              readonly
              filled
              background-color="#EFEFF7"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row v-if="loading" class="justify-center" :loading="loading">
      <Loading :loading="loading"></Loading>
    </v-row>
  </v-container>
</template>
<script>
import Loading from "@/components/common/Loading";

export default {
  name: "ClientDetail",
  components: {
    Loading,
  },
  data() {
    return {
      client: {},
      loading: false,
      page: 1,
      per_page: 30,
      pageCount: 1,
      answerCount: null,
      totalPrice: null,
      total: null,
      answer: null,
    };
  },
  mounted() {
    this.getData();
    this.getMedia();
  },
  methods: {
    getData() {
      this.loading = true;
      this.axios
        .get("api/v1/admin/clients/" + this.$route.params.id)
        .then((res) => {
          this.client = res.data.data;
          this.media_ids = res.data.data.medias.map((item) => item.id);
          this.loading = false;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>
